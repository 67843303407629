import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {media} from '../../../utils'
import theme from '../../../theme/theme'
import PrimaryButton from '../../atoms/PrimaryButton'
import Typography from '../../atoms/Typography'


const StyledBackgroundContainer = styled.div`
  margin: ${({bottom}) => bottom ? '0' : '5rem 0'};
  background-color: ${theme.colors.gray98};

  ${media.down('mobile')(css`
    display: ${({mobileCTAVisible}) => mobileCTAVisible ? 'block' : 'none'};
    margin: ${({bottom}) => bottom ? '4rem 0' : '3rem 0 7rem'};
  `)}
`

const StyledCallToActionContainer = styled.div`
  display: flex;
  align-items: center;
  height: 10.5rem;
`

const StyledTypography = styled(Typography)`
  margin-left: 6rem;
  width: 75%;

  ${media.down('mobile')(css`
    display: none;
  `)}
`

const StyledButtonContainer = styled.div`
  background-color: ${theme.colors.lightGray};
  width: 25%;
  height: 10.5rem;

  ${media.down('mobile')(css`
    display: flex;
    width: 100%;
    justify-content: center;
    padding-bottom: 9rem;
    background: linear-gradient(90deg, ${theme.colors.gray98} 40%, ${theme.colors.lightGray} 45%);
  `)}
`

const StyledButton = styled(PrimaryButton)`
  top: 4.4rem;
  z-index: 1;
  padding: 0 1.5rem;

  &::before {
    left: -3.5rem;
    opacity: 1;
    z-index: -1;
    border-radius: 0 50% 50% 0;
    background-color: ${theme.colors.gray98};
    width: 10.5rem;
    height: 10.5rem;
  }

  &:hover,
  &:focus {
    &::before {
      border-radius: 0;
      width: 45rem;

      ${media.down('mobile')(css`
        width: 45rem;
      `)}
    }
  }
`

const SectionCallToAction = ({bottom, mobileCTAVisible, link, linkText, children, ...props}) => {
  return (
    <StyledBackgroundContainer bottom={bottom} mobileCTAVisible={mobileCTAVisible} {...props}>
      <StyledCallToActionContainer {...props}>
        <StyledTypography variant="h2" type="h3">{children}</StyledTypography>
        <StyledButtonContainer>
          <StyledButton transparent to={link}>{linkText}</StyledButton>
        </StyledButtonContainer>
      </StyledCallToActionContainer>
    </StyledBackgroundContainer>
  )
}

SectionCallToAction.defaultProps = {
  mobileCTAVisible: false,
}

SectionCallToAction.propTypes = {
  bottom: PropTypes.bool,
  mobileCTAVisible: PropTypes.bool,
  link: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default SectionCallToAction
